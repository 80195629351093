var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "pb-1" },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex align-center justify-center" },
                [
                  _c("invoice-property-detail", {
                    attrs: { property: _vm.invoice.property }
                  }),
                  _vm.screen
                    ? _c("v-divider", {
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      })
                    : _vm._e(),
                  _c("invoice-order-detail", {
                    attrs: { invoice: _vm.invoice, mode: _vm.mode }
                  }),
                  _vm.screen
                    ? _c("v-divider", {
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      })
                    : _vm._e(),
                  _vm.screen
                    ? _c("psi-stats-card", {
                        staticClass: "mr-2 ml-2",
                        attrs: {
                          "hide-actions": "",
                          "color-option": 2,
                          title: "Invoice Amount",
                          icon: "mdi-currency-usd",
                          stat: _vm.$dollarFormat(
                            _vm.$_.get(_vm.invoice, "invoice_amount")
                          )
                        }
                      })
                    : _vm._e(),
                  _vm.screen
                    ? _c("v-divider", {
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      })
                    : _vm._e(),
                  _vm.screen
                    ? _c("psi-stats-card", {
                        staticClass: "mr-2 ml-2",
                        attrs: {
                          "hide-actions": "",
                          "color-option": 4,
                          title: "Paid Amount",
                          icon: "mdi-currency-usd",
                          stat: _vm.$dollarFormat(
                            _vm.$_.get(_vm.invoice, "paid_amount")
                          )
                        }
                      })
                    : _vm._e(),
                  _vm.screen
                    ? _c("v-divider", {
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      })
                    : _vm._e(),
                  _vm.screen
                    ? _c("psi-stats-card", {
                        staticClass: "mr-4 ml-2",
                        attrs: {
                          "hide-actions": "",
                          "color-option": 3,
                          title: "Amount Due",
                          icon: "mdi-currency-usd",
                          stat: _vm.$dollarFormat(
                            _vm.$_.get(_vm.invoice, "due_amount")
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }