<template>
    <v-row>
        <v-col cols="12">
            <v-card class="pb-1">
                <v-card-text class="d-flex align-center justify-center">
                    <invoice-property-detail
                        :property="invoice.property"
                    ></invoice-property-detail>
                    <v-divider vertical class="mx-2" v-if="screen"></v-divider>
                    <invoice-order-detail
                        :invoice="invoice"
                        :mode="mode"
                    ></invoice-order-detail>
                    <v-divider vertical class="mx-2" v-if="screen"></v-divider>
                    <psi-stats-card
                        class="mr-2 ml-2"
                        hide-actions
                        :color-option="2"
                        title="Invoice Amount"
                        icon="mdi-currency-usd"
                        :stat="$dollarFormat($_.get(invoice, 'invoice_amount'))"
                        v-if="screen"
                    >
                    </psi-stats-card>
                    <v-divider vertical class="mx-2" v-if="screen"></v-divider>
                    <psi-stats-card
                        class="mr-2 ml-2"
                        hide-actions
                        :color-option="4"
                        title="Paid Amount"
                        icon="mdi-currency-usd"
                        :stat="$dollarFormat($_.get(invoice, 'paid_amount'))"
                        v-if="screen"
                    >
                    </psi-stats-card>
                    <v-divider vertical class="mx-2" v-if="screen"></v-divider>
                    <psi-stats-card
                        class="mr-4 ml-2"
                        hide-actions
                        :color-option="3"
                        title="Amount Due"
                        icon="mdi-currency-usd"
                        :stat="$dollarFormat($_.get(invoice, 'due_amount'))"
                        v-if="screen"
                    >
                    </psi-stats-card>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "invoice-summary",
    components: {
        InvoicePropertyDetail: () => import("./InvoicePropertyDetail"),
        InvoiceOrderDetail: () => import("./InvoiceOrderDetail"),
    },
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        screen: {
            type: Boolean,
            required: false,
            default: true,
        },
        print: {
            type: Boolean,
            required: false,
            default: false,
        },
        mode: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>